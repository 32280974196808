import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t } = useTranslation();

  return (
    <footer className="bg-white text-black py-2">
      <div className="text-center text-sm">
        {t('footer')}
      </div>
    </footer>
  );
}

export default Footer;
