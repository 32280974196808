import React, { useEffect, useRef, useState } from "react";
import BoxReveal from "../magicui-components/magicui/box-reveal";
import PulsatingButton from "../magicui-components/magicui/pulsating-button";
import {
  FaPlay,
  FaPause,
  FaVolumeMute,
  FaVolumeUp,
  FaExpand,
  FaArrowUp,
} from "react-icons/fa";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isInstagramBrowser, setIsInstagramBrowser] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);

    const userAgent = window.navigator.userAgent || "";
    setIsInstagramBrowser(userAgent.includes("Instagram"));
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const togglePause = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
    (e.target as HTMLElement).blur();
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const enterFullscreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      }
    }
  };

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (videoElement.readyState >= 3 && videoElement.paused) {
            videoElement.play().catch((error) => {
              console.warn("Failed to play the video:", error);
            });
            setIsPaused(false);
          }
        } else {
          if (!videoElement.paused) {
            videoElement.pause();
            setIsPaused(true);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.3,
    });

    observer.observe(videoElement);

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, [isMuted]);

  return (
    <div className="relative lg:h-[70vh] h-[70vh] bg-cover bg-center">
      {isInstagramBrowser && (
        <div className="fixed top-0 left-0 w-full bg-[#4CAF50] bg-opacity-70 text-white flex justify-end items-center pt-3 pb-2 px-1 z-50">
          <p className="text-xs p-1 rounded leading-none">
            {t("ig_browser_message")}
          </p>
          <FaArrowUp size={14} className="text-white" />
        </div>
      )}

      <div className="absolute z-50 left-0 bottom-0 mb-2 ml-4 md:bottom-auto md:left-auto md:right-0 md:top-0 md:mt-6 md:mr-4">
        <LanguageSelector />
      </div>

      <div className="absolute inset-0 z-0">
        {!isVideoLoaded && (
          <img
            src="/images/hero.webp"
            alt="Placeholder"
            className="absolute inset-0 z-10 w-full h-full object-cover"
          />
        )}

        <video
          ref={videoRef}
          src="https://player.vimeo.com/progressive_redirect/playback/1026221851/rendition/720p/file.mp4?loc=external&log_user=0&signature=a839863bfe30e2bde27eabe9b2ce169db80acb4442762515e1f1af26de229bc2"
          autoPlay
          muted={isMuted}
          loop
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover"
          onLoadedData={handleVideoLoad}
        />
      </div>

      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>

      <div className="absolute bottom-4 right-4 z-30 space-x-4 flex text-white">
        <div onClick={togglePause} className="cursor-pointer">
          {isPaused ? <FaPlay size={24} /> : <FaPause size={24} />}
        </div>

        <div onClick={toggleMute} className="cursor-pointer">
          {isMuted ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
        </div>

        <div onClick={enterFullscreen} className="cursor-pointer">
          <FaExpand size={24} />
        </div>
      </div>

      <div className="relative z-20 flex flex-col items-center justify-start md:justify-center h-full text-white text-center space-y-0 md:space-y-8 px-4 pb-4 sm:pb-32">
        <BoxReveal boxColor={"#4CAF50"} duration={1}>
          <img
            src="/images/logo-desktop.webp"
            alt="Logo"
            className="hidden md:block h-48 lg:h-56 md:mb-2 mt-2"
            tabIndex={-1}
          />
        </BoxReveal>
        <BoxReveal boxColor={"#4CAF50"} duration={1}>
          <img
            src="/images/logo-mobile.webp"
            alt="Logo"
            className="block md:hidden sm:mb-8 mt-12"
            tabIndex={-1}
          />
        </BoxReveal>

        <div className="flex flex-grow md:flex-grow-0 flex-col items-center justify-center space-y-4 mt-0">
          <BoxReveal boxColor={"#4CAF50"} duration={1}>
            <p
              className="font-medium text-3xl sm:text-4xl md:text-5xl tracking-normal pb-2"
              style={{ lineHeight: "1.1" }}
            >
              {t("hero1")}
            </p>
          </BoxReveal>

          <BoxReveal boxColor={"#4CAF50"} duration={1}>
            <p className="text-md sm:text-lg max-w-2xl tracking-normal">
              {t("hero2")}
            </p>
          </BoxReveal>

          <BoxReveal boxColor={"#4CAF50"} duration={1}>
            <div className="flex space-x-4">
              <PulsatingButton
                className="bg-white m-2 text-[#4CAF50]"
                pulseColor="rgb(200, 255, 160)"
                onClick={scrollToBottom}
              >
                {t("contact_us")}
              </PulsatingButton>
            </div>
          </BoxReveal>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
