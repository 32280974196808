import React, { useState, useEffect } from "react";
import ARViewer from "./ArViewer";
import { useMediaQuery } from "@custom-react-hooks/use-media-query";
import { useTranslation } from "react-i18next";
import { AiOutlineWarning } from 'react-icons/ai';
import { MdWarning } from 'react-icons/md';

const ModelSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPhone, setIsPhone] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const [isARSupported, setIsARSupported] = useState(false);

  const handleARSupport = (isSupported: boolean) => {
    setIsARSupported(isSupported);
  };


  const models = [
    {
      modelUrl: "/models/burger.glb",
      iosModelUrl: "/models/burger.usdz",
      azimuth: 140,
    },
    {
      modelUrl: "/models/pastrami.glb",
      iosModelUrl: "/models/pastrami.usdz",
      fow: 50,
    },
    {
      modelUrl: "/models/wafla.glb",
      iosModelUrl: "/models/wafla.usdz",
      azimuth: 270,
      fow: 45,
      elevation: 60,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === models.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? models.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center mb-2">
        <h2 className="text-4xl font-bold text-gray-900">{t("model_title")}</h2>
        <p className="mt-4 text-lg text-gray-600">{t("model_subtitle")}</p>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          {models.map((model, index) => (
            (!isPhone || (isPhone && currentIndex === index)) && (
              <div key={index} className="relative flex flex-col items-center px-2">
                {isPhone && (
                  <button
                    className="absolute left-0 p-2 text-gray-600 hover:text-black focus:outline-none z-10"
                    onClick={goToPrev}
                    onMouseDown={(e) => (e.currentTarget.style.transform = "scale(1.3)")}
                    onMouseUp={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    onTouchStart={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                    onTouchEnd={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    style={{ left: "0.5rem", top: "50%", fontSize: "32px" }}
                  >
                    &#8592;
                  </button>
                )}
                <ARViewer
                  modelUrl={model.modelUrl}
                  iosModelUrl={model.iosModelUrl}
                  azimuth={model.azimuth}
                  fow={model.fow}
                  elevation={model.elevation}
                  onARSupportCheck={handleARSupport}
                />
                {isPhone && (
                  <button
                    className="absolute right-0 p-2 text-gray-600 hover:text-black focus:outline-none z-10"
                    onClick={goToNext}
                    onMouseDown={(e) => (e.currentTarget.style.transform = "scale(1.3)")}
                    onMouseUp={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    onTouchStart={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                    onTouchEnd={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    style={{ right: "0.5rem", top: "50%", fontSize: "32px" }}
                  >
                    &#8594;
                  </button>
                )}
                
              </div>
            )
          ))}
                {!isARSupported && !isDesktop ? <div className="flex items-center justify-center mt-2 text-xs text-gray-500 mt-[-16px] mb-[-16px] ">
                    <AiOutlineWarning className="mr-2 text-3xl" />
                    <span>
                         {t('ar_not_supported')}
                    </span>
                </div> : null }

        </div>
      </div>
    </section>
  );
};

export default ModelSection;
