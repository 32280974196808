import React, { useEffect, useRef, useState } from 'react';
import '@google/model-viewer';
import loading from '../loading.svg';

interface ARViewerProps {
    modelUrl: string;
    iosModelUrl: string;
    azimuth?: number;
    elevation?: number;
    fow?: number;
    onARSupportCheck?: (isSupported: boolean) => void;
  }

  const ARViewer: React.FC<ARViewerProps> = ({ modelUrl, iosModelUrl, azimuth, elevation, fow, onARSupportCheck }) => {
    const modelViewerRef = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [, setCanActivateAR] = useState(false);

    useEffect(() => {
      const modelViewerElement = modelViewerRef.current as any;

      const handleModelLoad = () => {
        setIsLoading(false);
      };

      if (modelViewerElement) {
        modelViewerElement.addEventListener('load', handleModelLoad);

        const isSupported = modelViewerElement.canActivateAR;
        setCanActivateAR(isSupported);
        if (onARSupportCheck) {
          onARSupportCheck(isSupported);
      }
      }

      return () => {
        if (modelViewerElement) {
          modelViewerElement.removeEventListener('load', handleModelLoad);
        }
      };
    }, [onARSupportCheck]);

    return (
      <div style={{ position: 'relative', width: '100%', height: '300px' }}>
        {isLoading && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1
          }}>
            <img
              src={loading}
              alt="Loading"
              style={{
                width: '100px',
                height: '100px'
              }}
            />
          </div>
        )}

        <model-viewer
          ref={modelViewerRef}
          src={modelUrl}
          ios-src={iosModelUrl}
          ar
          ar-modes="scene-viewer quick-look webxr"
          camera-controls
          auto-rotate
          disable-tap
          exposure="0.7"
          environment-intensity="0.7"
          shadow-intensity="1"
          environment-image="neutral"
          loading="lazy"
          style={{ width: '100%', height: '100%' }}
          min-camera-orbit="auto 30deg auto"
          max-camera-orbit="auto 90deg auto"
          camera-orbit={`${azimuth}deg ${elevation}deg 2.5m`}
          field-of-view={`${fow}deg`}
        />
      </div>
    );
  };

  export default ARViewer;
