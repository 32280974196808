"use client";

import React, { useRef } from "react";
import { AnimatedBeam } from "../magicui-components/magicui/animated-beam";
import '../App.css';

export function BeamSection() {
    const containerRef = useRef<HTMLDivElement>(null);
    const div1Ref = useRef<HTMLDivElement>(null);
    const div2Ref = useRef<HTMLDivElement>(null);

    return (
        <div
            className="relative flex w-full items-center justify-center overflow-hidden bg-background px-0 py-4 md:p-10"
            ref={containerRef}
        >
            <div className="flex h-full w-full flex-row items-stretch justify-between gap-3 md:gap-24 px-0 md:px-24 lg:px-40 xl:px-52">
                <div ref={div1Ref} className="z-10 flex h-48 w-48 md:h-64 md:w-64 lg:h-96 lg:w-96 items-center justify-center rounded-full bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]">
                    <img
                        src="/images/pizza.webp"
                        alt="Food"
                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                    />
                </div>
                <div ref={div2Ref} className="z-10 flex h-48 w-48 md:h-64 md:w-64 lg:h-96 lg:w-96 items-center justify-center rounded-full bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]">
                    <model-viewer
                        src="/models/pizza.glb"
                        ios-src="/models/pizza.usdz"
                        auto-rotate
                        camera-controls
                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                    ></model-viewer>
                </div>
            </div>

            <AnimatedBeam
                pathWidth={12}
                duration={3}
                containerRef={containerRef}
                fromRef={div1Ref}
                toRef={div2Ref}
                startYOffset={10}
                endYOffset={10}
                curvature={-100}
                gradientStartColor="#4CAF50"
                gradientStopColor="#EBFFE8"
            />
            <AnimatedBeam
                pathWidth={12}
                duration={3}
                containerRef={containerRef}
                fromRef={div1Ref}
                toRef={div2Ref}
                startYOffset={-10}
                endYOffset={-10}
                curvature={100}
                gradientStartColor="#4CAF50"
                gradientStopColor="#EBFFE8"
                reverse
            />
        </div>
    );
}
