import React, { useState, useEffect } from 'react'
import Iphone15Pro from '../magicui-components/magicui/iphone-15-pro'
import { useMediaQuery } from "@custom-react-hooks/use-media-query";
import { Button } from "../magicui-components/magicui/button";
import { PiHandTap } from "react-icons/pi";
import { PiCursorClick } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const ProductSection = () => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const [isPhone, setIsPhone] = useState(false);

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const { t } = useTranslation();

const iphoneContent = [
  {
    src: '/images/menu.webp',
    label: t('product_menu'),
  },
  {
    src: '/images/modelmenu.webp',
    label: t('product_model')
  },
  {
    src: '/images/modelar.webp',
    label: t('product_ar')
  }
];

useEffect(() => {
  const handleResize = () => {
    setIsPhone(window.innerWidth < 640);
  };

  handleResize();
  window.addEventListener('resize', handleResize);

  return () => window.removeEventListener('resize', handleResize);
}, []);

const goToNext = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex === iphoneContent.length - 1 ? 0 : prevIndex + 1
  );
};

const goToPrev = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex === 0 ? iphoneContent.length - 1 : prevIndex - 1
  );
};



return (
  <div className="relative flex flex-col items-center justify-center bg-[#EBFFE8] py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">{t('product_title')}</h2>
        <p className="mt-4 text-lg text-gray-600">
          {t('product_subtitle')}
        </p>
      </div>
      <div className="relative w-full max-w-6xl mx-auto">

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          {iphoneContent.map((item, index) => (

            (!isPhone || (isPhone && currentIndex === index)) && (
              <div key={index} className="relative flex flex-col items-center">

                {isPhone && (
                  <button
                    className="absolute left-0 p-2 text-gray-600 hover:text-black focus:outline-none z-10"
                    onClick={goToPrev}
                    onMouseDown={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                    onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    onTouchStart={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                    onTouchEnd={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    style={{ left: '0.5rem', top: '40%', fontSize: '32px' }}
                  >
                    &#8592;
                  </button>
                )}

                <Iphone15Pro
                  className="w-60 sm:w-48 lg:w-64 xl:w-72 h-auto"
                  src={item.src}
                />
                <p className="mt-6 text-center text-md sm:text-base text-gray-600">
                  {item.label}
                </p>


                {isPhone && (
                  <button
                    className="absolute right-0 p-2 text-gray-600 hover:text-black focus:outline-none z-10"
                    onClick={goToNext}
                    onMouseDown={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                    onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    onTouchStart={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                    onTouchEnd={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    style={{ right: '0.5rem', top: '40%', fontSize: '32px' }}
                  >
                    &#8594;
                  </button>
                )}
              </div>
            )
          ))}
        </div>
      </div>
      {/* New Section to view full webpage */}
      <div className="mt-8 flex flex-col items-center">
  <a href="https://ibatu.virtualbites.sk" target="_blank" rel="noopener noreferrer">
    <div className="flex items-center justify-center w-24 h-28 relative">
      <img
        src="/images/ibatu.webp"
        alt="ibatu logo"
        className="w-full h-full object-contain rounded-lg"
      />
      <Button
        className="absolute -bottom-4 -right-2 px-2 py-2 rounded-xl opacity-80"
        style={{
          backgroundColor: '#FFFFFF',
        }}
      >
        {!isDesktop ? (
          <PiHandTap
            size={28}
            style={{
              color: '#15803d',
            }}
          />
        ) : (
          <PiCursorClick
            size={28}
            style={{
              color: '#15803d',
            }}
          />
        )}
      </Button>
    </div>
  </a>
  <p className="text-gray-600 text-md mt-4">{t('product_action')}</p>
</div>
    </div>
);
}

export default ProductSection
