import React from 'react';
import './index.css';
import 'react-multi-carousel/lib/styles.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PricingPage from './pages/PricingPage';
import MainPage from './pages/MainPage';
import Bowser from 'bowser';
import './i18n'
import { useTranslation } from 'react-i18next';

import { FaChrome, FaFirefoxBrowser, FaSafari, FaEdge } from 'react-icons/fa';

const App: React.FC = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName().toLowerCase();
    const allowedBrowsers: string[] = ["chrome", "safari", "firefox", "microsoft edge"];

    const { t } = useTranslation();

    const UnsupportedBrowser: React.FC = () => (
        <div className="flex flex-col items-center justify-center h-screen text-center p-6 font-sans bg-[#EBFFE8]">
            <img
                src="/images/logo-pricing.webp"
                alt="Logo Large"
                className="hidden sm:block w-4/5 mb-5 max-w-2xl"
            />
            <img
                src="/images/logo-black-mobile.webp"
                alt="Logo Mobile"
                className="block sm:hidden w-3/4 mb-5 max-w-xs"
            />

            <p className="text-lg text-gray-700 mb-6">
                {t('unsupported_browser')}
            </p>

            <div className="grid grid-cols-2 gap-8 mt-5 sm:grid-cols-4">
                <div className="flex flex-col items-center text-black">
                    <FaChrome size={32} />
                    <span className="text-sm">Chrome</span>
                </div>
                <div className="flex flex-col items-center text-black">
                    <FaFirefoxBrowser size={32} />
                    <span className="text-sm">Firefox</span>
                </div>
                <div className="flex flex-col items-center text-black">
                    <FaSafari size={32} />
                    <span className="text-sm">Safari</span>
                </div>
                <div className="flex flex-col items-center text-black">
                    <FaEdge size={32} />
                    <span className="text-sm">Edge</span>
                </div>
            </div>
        </div>
    );

    if (!allowedBrowsers.includes(browserName)) {
        return <UnsupportedBrowser />;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/cennik" element={<PricingPage />} />
            </Routes>
        </Router>
    );
};

export default App;
