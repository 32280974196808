import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; 
import Footer from '../components/Footer';
import BannerSection from '../components/BannerSection';

const PricingPage = () => {

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className='bg-[#EBFFE8] min-h-screen'>
      <div className="flex justify-between items-center p-6">
        <img src="/images/logo-pricing.webp" alt="Logo" className="h-12" /> 
      </div>

      <div className="flex flex-col justify-end p-8 pt-4 relative mt-8">
        <div className="bg-white shadow-md rounded-lg p-6 pb-4 max-w-lg w-full mx-auto mb-8">
          <h2 className="text-3xl font-bold text-center mb-4">Balíček služieb</h2>
          <p className="text-5xl font-bold text-center text-green-600 mb-2">89 €</p>
          <p className="text-sm text-center text-gray-600 mb-4">Mesačne</p>
          
          <div className="border-t border-gray-200 py-4">
            <p className="text-sm text-center">Predplatné zahŕňa:</p>
            <ul className="list-none text-left mt-2">
              <li className="flex items-center mb-3">
                <FaCheckCircle className="text-green-600 mr-2 h-4 w-4" />
                <span className="flex-1">
                  Počiatočná tvorba 3D modelov jedál (do 40 modelov)
                </span>
              </li>
              <li className="flex items-center mb-3">
                <FaCheckCircle className="text-green-600 mr-2 h-4 w-4" />
                <span className="flex-1">Priebežná aktualizácia menu</span>
              </li>
              <li className="flex items-center mb-3">
                <FaCheckCircle className="text-green-600 mr-2 h-4 w-4" />
                <span className="flex-1">Prispôsobenie menu podľa vašich požiadaviek</span>
              </li>
              <li className="flex items-center mb-3">
                <FaCheckCircle className="text-green-600 mr-2 h-4 w-4" />
                <span className="flex-1">Profesionálne fotografie jedál</span>
              </li>
              <li className="flex items-center mb-3">
                <FaCheckCircle className="text-green-600 mr-2 h-4 w-4" />
                <span className="flex-1">Hosting, údržba a podpora</span>
              </li>
            </ul>
          </div>

          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">Ročne:</p>
            <div className="flex justify-center items-end">
              <span className="line-through text-red-500 text-lg mr-2">1 068 €</span>
              <p className="text-2xl font-bold text-green-600">899 €</p>
            </div>
            <p className="text-sm text-gray-600 mb-4">(ušetríte 169 €)</p>

            <button className="bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300" onClick={scrollToBottom}>
              Mám záujem
            </button>
            
            <p className="mt-4 text-xs text-gray-500">Po uplynutí ročnej viazanosti môžete službu kedykoľvek zrušiť. V prípade špecifických požiadaviek nás kontaktujte.</p>
          </div>
        </div>

        <BannerSection />
      </div>
      
      <Footer />
    </div>
  );
};

export default PricingPage;
