import React from 'react'
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const BannerSection = () => {

  const { t } = useTranslation();

  return (
    <div className="mt-12 mb-4 bg-gradient-to-br from-green-500 to-green-700 p-12 rounded-xl flex flex-col sm:flex-row items-center justify-between max-w-6xl mx-2 md:mx-4 lg:mx-8 xl:mx-auto">
      {/* Left Side: Text and Contact Info */}
      <div className="text-white max-w-lg">
        <h2 className="text-4xl font-bold mb-4">{t('banner1')}</h2>
        <p className="text-lg mb-8">
          {t('banner2')}
        </p>

        {/* New Contact Section */}
        <div className="mt-6 space-y-6">
            {/* Email */}
            <div className="flex items-center text-3xl">
              <FaEnvelope className="mr-4 text-3xl text-[#FFD700]" />
              <a href="mailto:info@virtualbites.sk" className="font-bold text-white text-2xl">
                info@virtualbites.sk
              </a>
            </div>

            {/* Phone */}
            <div className="flex items-center text-3xl">
              <FaPhoneAlt className="mr-4 text-3xl text-[#FFD700]" />
              <a href="tel:+421902119962" className="font-bold text-white text-2xl">
                +421 902 119 962
              </a>
            </div>
        </div>
      </div>

      {/* Right Side: Image */}
      <div className="mt-8 sm:mt-0 sm:ml-12">
        <img
          src="/images/banner.webp"
          alt="3D Food Model"
          className="rounded-lg shadow-lg max-w-full"
        />
      </div>
    </div>
  )
}

export default BannerSection
