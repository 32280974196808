import { t } from 'i18next';
import React from 'react'
import { FaUtensils, FaChartLine, FaPlug } from 'react-icons/fa'
import { TbAugmentedReality } from "react-icons/tb";

const FeaturesSection = () => {
  return (
    <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-6">
            <h2 className="text-4xl font-bold text-gray-900">{t('features_title')}</h2>
            <p className="mt-4 text-lg text-gray-600">
              {t('features_subtitle')}
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {/* Card 1 */}
            <div className="group relative bg-white border border-gray-200 rounded-lg p-6 shadow-lg hover:bg-gray-100 transition-all">
              <FaUtensils className="text-green-500 mb-4 text-3xl" />
              <h3 className="text-xl font-semibold text-gray-900">{t('feature1_title')}</h3>
              <p className="mt-2 text-gray-600">
                {t('feature1_desc')}
              </p>
            </div>

            {/* Card 2 */}
            <div className="group relative bg-white border border-gray-200 rounded-lg p-6 shadow-lg hover:bg-gray-100 transition-all">
              <TbAugmentedReality className="text-green-500 mb-2 text-4xl" />
              <h3 className="text-xl font-semibold text-gray-900">{t('feature2_title')}</h3>
              <p className="mt-2 text-gray-600">
                {t('feature2_desc')}  
              </p>
            </div>

            {/* Card 3 */}
            <div className="group relative bg-white border border-gray-200 rounded-lg p-6 shadow-lg hover:bg-gray-100 transition-all">
              <FaChartLine className="text-green-500 mb-4 text-3xl" />
              <h3 className="text-xl font-semibold text-gray-900">{t('feature3_title')}</h3>
              <p className="mt-2 text-gray-600">
                {t('feature3_desc')} 
              </p>
            </div>

            {/* Card 4 */}
            <div className="group relative bg-white border border-gray-200 rounded-lg p-6 shadow-lg hover:bg-gray-100 transition-all">
              <FaPlug className="text-green-500 mb-4 text-3xl" />
              <h3 className="text-xl font-semibold text-gray-900">{t('feature4_title')}</h3>
              <p className="mt-2 text-gray-600">
                {t('feature4_desc')}
              </p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default FeaturesSection