import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../magicui-components/magicui/select'

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState('sk');

  const languages = [
    { value: 'sk', flag: '/flags/sk.png', label: 'Slovak' },
    { value: 'en', flag: '/flags/uk.png', label: 'English' },
  ];

  const changeLanguage = useCallback(
    (lng: string) => {
      i18n.changeLanguage(lng);
    },
    [i18n]
  );

  useEffect(() => {
    changeLanguage(value);
  }, [value, changeLanguage]);

  return (
    <Select value={value} onValueChange={setValue}>
      <SelectTrigger className="w-[60px] bg-white border-2 border-white/30 p-1 rounded-lg shadow-md cursor-pointer outline-none transition-colors duration-300 ease-in-out hover:border-white/80 focus:border-white/60 flex items-center justify-center">
        <SelectValue
          className="flex items-center pr-8"
          placeholder={
            <img
              src={languages.find((lang) => lang.value === value)?.flag}
              alt="selected flag"
              className="w-5 h-5"
            />
          }
        />
      </SelectTrigger>
      <SelectContent className="min-w-[60px] bg-gray-200 text-white rounded-lg shadow-lg">
        {languages.map((lang) => (
          <SelectItem key={lang.value} value={lang.value} className="p-2 flex items-center cursor-pointer">
            <img src={lang.flag} alt={`${lang.label} flag`} className="inline-block w-5 h-5 mr-2" />
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default LanguageSelector;
