import React from 'react';
import {FaPencilAlt, FaPlug, FaCog, FaInfoCircle, FaHandshake} from 'react-icons/fa';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const ServicesSection = () => {

  const { t } = useTranslation();

  return (
      <div className="bg-[#EBFFE8] py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-10">
            <h2 className="text-4xl font-bold text-gray-900">{t('services_title')}</h2>
            <p className="mt-4 text-lg text-gray-600">
              {t('services_subtitle')}  
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 text-left">
            <div className="flex items-start">
              <div style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '12px' }}>
                <FaHandshake style={{ fontSize: '20px', color: 'green' }} />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('service1_title')}</h3>
                <p className="mt-2 text-gray-600">
                  {t('service1_desc')}
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '12px' }}>
                <MdOutlinePhotoCamera style={{ fontSize: '20px', color: 'green' }} />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('service2_title')}</h3>
                <p className="mt-2 text-gray-600">
                  {t('service2_desc')}
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '12px' }}>
                <FaPencilAlt style={{ fontSize: '20px', color: 'green' }} />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('service3_title')}</h3>
                <p className="mt-2 text-gray-600">
                  {t('service3_desc')}
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '12px' }}>
                <FaPlug style={{ fontSize: '20px', color: 'green' }} />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('service4_title')}</h3>
                <p className="mt-2 text-gray-600">
                  {t('service4_desc')}
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '12px' }}>
                <FaCog style={{ fontSize: '20px', color: 'green' }} />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('service5_title')}</h3>
                <p className="mt-2 text-gray-600">
                  {t('service5_desc')}
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '12px' }}>
                <FaInfoCircle style={{ fontSize: '20px', color: 'green' }} />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{t('service6_title')}</h3>
                <p className="mt-2 text-gray-600">
                  {t('service6_desc')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ServicesSection;
