import React from 'react'
import BlurFade from '../magicui-components/magicui/blur-fade'
import HeroSection from '../components/HeroSection'
import FeaturesSection from '../components/FeaturesSection'
import ProductSection from '../components/ProductSection'
import ModelSection from '../components/ModelSection'
import ServicesSection from '../components/ServicesSection'
import BannerSection from '../components/BannerSection'
import { BeamSection } from '../components/BeamSection'
import Footer from '../components/Footer'

const MainPage = () => {
  return (
          <>
            <BlurFade delay={0.25} inView>
              <HeroSection />
            </BlurFade>
            <BlurFade delay={0.25} inView duration={1}>
              <FeaturesSection />
            </BlurFade>
            <BlurFade delay={0.25} inView duration={1}>
              <ProductSection />
            </BlurFade>
            {/* Uncomment if needed
            <BlurFade delay={0.25} inView duration={1}>
              <CustomMarquee />
            </BlurFade> */}
            <BlurFade delay={0.25} inView duration={1}>
              <ModelSection />
            </BlurFade>
            <BlurFade delay={0.25} inView duration={1}>
              <ServicesSection />
            </BlurFade>
            <div className="hidden md:block">
              <BlurFade delay={0.25} inView duration={1}>
                <BeamSection />
              </BlurFade>
            </div>
            <BlurFade delay={0.25} inView duration={1}>
              <BannerSection />
            </BlurFade>
            <Footer />
          </>
        );
}

export default MainPage